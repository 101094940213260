<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent title="등록 간병인 전체 목록" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="state"
          :items="[
            { text: '지원', value: 'apply' },
            { text: '매칭', value: 'done' }
          ]"
          label="간병인 필터 값"
          append-icon="expand_more"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        최대 지원 횟수 {{ stats.countCaregiverMaxApply }}, 최대 매칭 횟수 {{ stats.countCaregiverMaxDone }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="min" label="최소값" />
      </v-col>
      <v-col>
        <v-text-field v-model="max" label="최대값" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="getCaregivers">조회하기</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col> 총{{ data.length }}명</v-col>
      <v-col>
        <v-btn @click="downloadExcel">엑셀</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="i in headers" :key="i">
                  {{ i }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, i) in data" :key="i">
                <td>
                  {{ v.user_idx }}
                </td>
                <td>
                  {{ v.caregiver_idx }}
                </td>
                <td>
                  {{ v.user_name }}
                </td>
                <td>
                  {{ v.user_phone }}
                </td>
                <td>
                  {{ v.user_gender }}
                </td>
                <td>
                  {{ v.user_age }}
                </td>
                <td>
                  {{ v.count }}
                </td>
                <td>
                  {{ v.caregiver_career }}
                </td>
                <td>
                  {{ v.caregiver_created_at }}
                </td>
                <td>
                  {{ v.caregiver_event_notification }}
                </td>
                <td>
                  {{ v.caregiver_introduce }}
                </td>
                <td>
                  {{ v.caregiver_office_hour }}
                </td>
                <td>
                  {{ transformLocationName(v.caregiver_working_area_idx) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
import Config from '@/config'
import exceljs from 'exceljs'
import dayjs from 'dayjs'
import PageTitleComponent from '../_elements/title/PageTitle'

const locations = [
  { text: '전체', value: 1 },
  { text: '서울', value: 2 },
  { text: '부산', value: 3 },
  { text: '대구', value: 4 },
  { text: '인천', value: 5 },
  { text: '광주', value: 6 },
  { text: '대전', value: 7 },
  { text: '울산', value: 8 },
  { text: '세종', value: 9 },
  { text: '경기', value: 10 },
  { text: '강원', value: 11 },
  { text: '충북', value: 12 },
  { text: '충남', value: 13 },
  { text: '전북', value: 14 },
  { text: '전남', value: 15 },
  { text: '경북', value: 16 },
  { text: '경남', value: 17 },
  { text: '제주', value: 18 }
]

export default {
  components: {
    PageTitleComponent
  },
  data() {
    return {
      headers: [
        'user_idx',
        'caregiver_idx',
        '이름',
        '전화번호',
        '성별',
        '나이',
        '(지원|매칭)횟수',
        '경력',
        '가입날짜',
        '알림 수신 동의',
        '자기소개',
        '근무시간',
        '일하는 지역'
      ],
      state: 'apply',
      min: 1,
      max: 10,
      stats: {
        countCaregiverMaxApply: null,
        countCaregiverMaxDone: null
      },
      data: []
    }
  },
  async mounted() {
    await this.getStatsCaregiverTask()
  },
  methods: {
    async getStatsCaregiverTask() {
      return axios.get(`${Config.Api.Service2.url}/caregiver/stats/caregiver-task`).then(({ data }) => {
        this.stats = data
      })
    },
    getCaregivers() {
      axios
        .get(`${Config.Api.Service2.url}/caregiver/by-task-state`, {
          params: {
            min: this.min,
            max: this.max,
            state: this.state
          }
        })
        .then(({ data }) => {
          this.data = data
        })
    },
    transformLocationName(id) {
      return locations.find(location => location.value === id).text
    },
    downloadExcel() {
      try {
        // 엑셀 생성
        const workbook = new exceljs.Workbook()

        workbook.addWorksheet('sheet')

        const sheet = workbook.getWorksheet('sheet')

        const columns = []
        Object.keys(this.data[0]).forEach(key =>
          columns.push({
            header: key,
            key
          })
        )
        sheet.columns = columns
        sheet.addRows(this.data.map(v => Object.values(v)))

        workbook.xlsx.writeBuffer().then(data => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const url = window.URL.createObjectURL(blob)
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.download = `헬퍼_간병인전체조회_${dayjs().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`
          anchor.click()
          window.URL.revokeObjectURL(url)
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
